<template>
    <v-container fluid>
        <v-row>
           <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                     <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
                <v-col>
                    <v-card color="blue lighten-5">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="office" 
                                        :items="offices" 
                                        default="" 
                                        item-value="office_id" 
                                        item-text="office" 
                                        label="Office ID"
                                        >
                                    </v-autocomplete>
                                    </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="trid" 
                                        :items="menu_headerss" 
                                        default="" 
                                        item-value="tr_id" 
                                        item-text="tr_id" 
                                        label="TR ID" 
                                        clearable 
                                        >
                                    </v-autocomplete>
                                    </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="tipe" 
                                        :items="menu_headerss" 
                                        default="" 
                                        item-value="tipe" 
                                        item-text="tipe" 
                                        label="Tipe" 
                                        clearable  
                                        >
                                    </v-autocomplete>
                                    </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        clearable 
                                        v-model="date_from"
                                        label="Start Date"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                               <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-menu
                                    ref="modal"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        clearable
                                        label="End Date"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                    </v-menu>
                                </v-col>    
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-btn class="mt-3" 
                                    color="info" elevation="2" large @click="getPullData()">Search</v-btn>
                                </v-col>
                             <v-row class="align-center mt-0 ma-0 pa-2">                            
                                <v-col cols="12" sm="6" md="2">
                                <v-card class="rounded-l" outlined>
                                    <v-card-text class="p-2">
                                        <v-row>
                                        <v-col cols="12">
                                            <div class="d-flex">
                                            <div class="ml-8">
                                                <p class="pb-1 mb-0">PENERIMAAN</p>
                                                <h6>
                                                  <p>Rp {{ $store.getters.convertToCurrency(penerimaan) }}</p>
                                                </h6>
                                            </div>
                                            </div>
                                        </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="2">
                                <v-card class="rounded-l" outlined>
                                    <v-card-text class="p-2">
                                        <v-row>
                                        <v-col cols="12">
                                            <div class="d-flex">
                                            <div class="ml-8">
                                                <p class="pb-1 mb-0">PEMBAYARAN </p>
                                                <h6>
                                                       <p>Rp {{ $store.getters.convertToCurrency(pembayaran) }}</p>
                                                </h6>
                                            </div>
                                            </div>
                                        </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="2">
                                <v-card class="rounded-l" outlined>
                                    <v-card-text class="p-2">
                                        <v-row>
                                        <v-col cols="12">
                                            <div class="d-flex">
                                            <div class="ml-8">
                                                <p class="pb-1 mb-0">SELISIH </p>
                                                <h6>
                                                      <p v-if="selisih !== null">Rp {{ $store.getters.convertToCurrency(selisih) }}</p>
                                                    <p v-else>-</p>
                                                </h6>
                                            </div>
                                            </div>
                                        </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            </v-row>
                                 <v-row class="mt-2" style="padding-bottom:50px">
                                    <v-col cols="12">
                                        <v-card class="rounded-l elevation-5 ml-3" color="blue lighten-5">
                                            <v-card-text>
                                                <h5>Result</h5>
                                                <v-row justify="end">
                                                  <v-col cols="12" xs="12" sm="12" md="2" class="text-right">
                                                     <v-btn class="mb-4 mr-2 text-white" 
                                                        color="#0D47A1" elevation="2" medium>
                                                        <download-excel
                                                        class="text"
                                                        :fetch           = "exportExcel"
                                                        :fields="headersColumn"
                                                        :before-generate = "startDownload"
                                                        :before-finish   = "finishDownload">
                                                        Export Excel
                                                        </download-excel> 
                                                     <v-icon>mdi-arrow-down-bold-box</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                 </v-row>
                                                <v-data-table
                                                    dense
                                                    :headers="headers"
                                                    :items="menu_headers"
                                                    :loading="loading"
                                                    loading-text="Please wait, retrieving data"
                                                    class="elevation-1"
                                                    >  
                                                </v-data-table>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
</template>

<script>
export default {
        data() {
            return {
                pembayaran: '',
                penerimaan: '',
                selisih: null,
                loading: false,
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
                },
                breadcumbs: [
                    {
                    text: 'Griya Kusuma',
                    disabled: false,
                    href: '/admin/gk',
                    },
                    {
                    text: 'Information',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                    {
                    text: 'Mutasi Finance',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                ],
                menu_headers:[],
                menu_headerss:[],
                headers:[
                    {
                    text: 'Office ID',
                    align: 'start',
                    value: 'office', 
                    align:'center'},
                    { text: 'TR ID', value: 'tr_id' , align:'center'},
                    { text: 'Tipe', value: 'tipe' , align:'center'},
                    { text: 'Tr Date', value: 'tr_date' , align:'center'},
                    { text: 'Note', value: 'item_notes' , align:'left'},
                    { text: 'Amount', value: 'amount' , align:'center'},
                ],
                offices: [{
                office_id : 'Griya Kusuma Indah',
                office : 'Griya Kusuma Indah'
                }],
                office: 'Griya Kusuma Indah',
                headersColumn: {
                'Office ':'office',
                'Tipe': 'tipe',
                'Tr ID': 'tr_id',
                'Tr Date' : 'tr_date',
                'Periode': 'periode',
                'Note': 'item_notes',
                'Amount': 'amount',
                },
                // offices:[],
                // office:'',
                datas:[],
                trid:'',
                tipe:'',
                date_from: '',
                date_to: '',
                modal_to:'',
                modal: false,
                modal2: false,
                    
            }
        },
        mounted(){
        this.GetIndex()   

        },
        methods:{    
        startDownload(){
           this.loading = true
           this.disabledExport=true
        },
        finishDownload(){
            this.loading = false
            this.disabledExport=false
        },
        async GetIndex(){
            await axios.get(`${process.env.VUE_APP_URL}/api/griya/mutasi`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.menu_headerss = res.data.datas
            })
            
        },    
        async exportExcel() {
            this.loading = true;
            this.$store.dispatch('setOverlay', true);
           if (this.office == '' || !this.date_from ) {
                this.loading_dialog = false
                 this.$store.dispatch('setOverlay', false)
                 this.snackbar = {
                  color: "error",
                  icon: "mdi-checkbox-marked-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 7500,
                  title: "Error",
                  text: "Please Input Start Date",
                  visible: true
                };
                this.$store.dispatch('setOverlay', false);
                this.loading = false;
            } else {
                var startDate = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : '';
                var endDate = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : new Date().toISOString().substr(0, 10).replace(/-/g,"");
                const response = await axios.get(`${process.env.VUE_APP_URL}/api/griya/mutasi/GetData?search=${this.$store.state.text}&tr_id=${this.trid ? this.trid : ''}&tipe=${this.tipe ? this.tipe : ''}&start_date=${startDate}&end_date=${endDate}`, {
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
                });
                this.$store.dispatch('setOverlay', false)
                this.loading = false;
                console.log(response.data);
                return response.data.datas
            }

        },
    async getPullData(){
            this.loading = true
            this.$store.dispatch('setOverlay', true)
            if (this.office == '' || !this.date_from ) {
                this.loading_dialog = false
                 this.$store.dispatch('setOverlay', false)
                 this.snackbar = {
                  color: "error",
                  icon: "mdi-checkbox-marked-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 7500,
                  title: "Error",
                  text: "Please Input Start Date",
                  visible: true
                };
            }else{
            this.loading = false
            var startDate = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : '';
            var endDate = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : new Date().toISOString().substr(0, 10).replace(/-/g,"");
            await axios.get(`${process.env.VUE_APP_URL}/api/griya/mutasi/GetData?search=${this.$store.state.text}&tr_id=${this.trid ? this.trid : ''}&tipe=${this.tipe ? this.tipe : ''}&start_date=${startDate}&end_date=${endDate}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.$store.dispatch('setOverlay', false)
                this.loading = false;
                this.menu_headers = res.data.datas;
                const summary = res.data.summary;
            if (summary && summary.length > 0) {
                    this.pembayaran = summary.find(s => s.tipe === 'PEMBAYARAN')?.total_amount || '';
                    this.penerimaan = summary.find(s => s.tipe === 'PENERIMAAN')?.total_amount || '';
            if (this.pembayaran && this.penerimaan) {
                    // console.log(this.pembayaran)
                this.selisih = this.pembayaran - this.penerimaan;
                    console.log(this.selisih);
            } else {
                this.selisih = 0;
                }

            } else {
                this.pembayaran = '';
                this.penerimaan = '';
                }
                
            })
            .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    console.log(err);
                });

            }

        },

        }         

    }
</script>

<style>

</style>